<template>
  <div class="module-box">
    <div class="module-title">增值服务</div>
    <div class="serv-box flex-between">
      <router-link :to="item.url" class="serv-item" v-for="(item, index) in list" :key="index">
        <img :src="item.photo" class="fit-img serv-item-bg" >
        <div class="serv-info">
          <div class="serv-title">
            {{ item.title }}
          </div>
          <div class="serv-desc text-nowrap">{{ item.titles }}</div>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      list: {
        type: Array,
      }
    },
  }
</script>

<style lang="less" scoped>
  @import "../style.less";
</style>